.about {
  background-image: url("../assets/black-silk.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.about-us-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
  color: lightblue;
  font-weight: 600;
  padding-top: 5em;
  padding-bottom: 5em;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .about-us-container {
    width: 70%;
  }

  .about-us-container p {
    text-align: start;
    font-size: 1em;
  }
}
