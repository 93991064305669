.home-container {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/party-lights.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4em;
  border-radius: 4px;
  padding: 8em;
  margin-top: 5em;
}

.intro p:first-child {
  text-align: center;
  font-size: 1.3em;
  color: lightblue;
  text-decoration: underline;
  font-weight: 900;
}

.intro p:nth-child(2) {
  color: white;
  text-align: center;
  font-weight: 600;
}

.cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10em;
}

@media only screen and (max-width: 600px) {
  .intro {
    width: 100%;
    padding: 2em;
    margin-top: 8em;
  }
  .image-container {
    height: 95vh;
  }
  .image-container img {
    height: 100%;
    object-fit: cover;
    object-position: 95%;
  }
}
