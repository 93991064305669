.party-container {
  background: url("../assets/black-silk.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.party-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

#bachelor-party {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#bachelor-party h5 + p {
  color: skyblue;
  font-weight: 600;
  font-size: 1em;
}

#bachelor-party p + p {
  text-align: start;
  line-height: 1.4em;
}

#topless-waitress {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#topless-waitress h5 + p {
  font-weight: 600;
  font-size: 1em;
  color: red;
}

#topless-waitress p + p {
  text-align: start;
  line-height: 1.4em;
}

#boat-yacht {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#boat-yacht h5 + p {
  color: gold;
  font-weight: 600;
  font-size: 1em;
}

#boat-yacht p + p {
  text-align: start;
  line-height: 1.4em;
}

#virtual {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#virtual h5 + p {
  color: gold;
  font-weight: 600;
  font-size: 1em;
}

#virtual p + p {
  text-align: start;
  line-height: 1.4em;
}

#birthday {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#birthday h5 + p {
  color: red;
  font-weight: 600;
  font-size: 1em;
}

#birthday p + p {
  text-align: start;
  line-height: 1.4em;
}

#holiday {
  margin-bottom: 10em;
  background-color: black;
  color: white;
  overflow: hidden;
}

#holiday h5 + p {
  color: red;
  font-weight: 600;
  font-size: 1em;
}

.package-price-title {
  text-align: center;
  color: lightblue;
  margin-bottom: 4em;
}

.price-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid lightblue;
  padding-top: 8em;
  width: 80%;
  margin: auto;
  margin-bottom: 5em;
  padding-bottom: 8em;
}

.price-list-one {
  font-size: 1.3em;
  color: lightblue;
}

.price-list-two {
  font-size: 1.3em;
  color: lightblue;
}

.disclaimer-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  text-align: center;
  color: white;
  padding-bottom: 5em;
}

.disclaimer-container p {
  font-weight: 600;
}

.disclaimer-container p + p {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .party-image-container {
    height: 95vh;
  }
  .price-list {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .party-image-container img {
    height: 100%;
    object-fit: cover;
  }
  .price-list-one {
    font-size: 1em;
    font-weight: 600;
  }
  .price-list-two {
    font-size: 1em;
    font-weight: 600;
  }
  .package-price-title {
    margin-bottom: 2em;
  }
}
